import React from "react";
import { Accordion, AccordionItem } from "@carbon/react";
import "./SideBar.scss";
import "./Description.scss";

function SideBar(props) {
  const handleQuestionClick = (questions) => {
    if (props.loading === false) {
      console.log("working?", questions);
      console.log("working?");
      var item = {
        type: "out",
        data: questions,
        source: "",
      };
      props.setInput(questions);
      props.setItems([...props.items, item]);
      props.setIsThereInput(true);
    }
  };
  return (
    <div className="sidebar">
      <p
        style={{ paddingTop: "30px", paddingBottom: "5px" }}
        className="instructions-header"
      >
        Sample Questions
      </p>
      {props.data.map((item, index) => (
        <Accordion>
          <AccordionItem className="accordion-item-sidebar" title={item.title}>
            {item.questions.map((question, qIndex) => (
              <p
                className="question-item"
                key={qIndex}
                style={{
                  textAlign: "left",
                  paddingTop: "3px",
                  paddingBottom: "3px"
                }}
              >
                <href
                  onClick={() => handleQuestionClick(question, item.title)}
                  style={{ textAlign: "left" }}
                >
                  {question}
                </href>
              </p>
            ))}
          </AccordionItem>
        </Accordion>
      ))}
    </div>
  );
}

export default SideBar;
