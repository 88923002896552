import {
  Col,
  Container,
  Row,
  InputGroup,
  Form,
  Button as ButtonBoostrap,
} from "react-bootstrap";
import "./ChatBox.scss";
import {
  Modal,
  Dropdown,
  TextInput,
  Button,
  Accordion,
  AccordionItem,
} from "@carbon/react";
import { Send } from "@carbon/icons-react";
import { useState, useRef, useEffect } from "react";
import { SSE } from "sse.js";

import {
  assistantServices,
  responseFromLLM,
  setDomain,
  updateFeedback,
} from "../util/api";

//A test for the batchupload to see how many simultanious requests can be made at the same time while streaming
export function TestStream(props) {
  const [loading, setLoading] = useState(true);
  const [streamData, setStreamData] = useState(new Array(50).fill(""));
  const streamRefs = useRef(new Array(50).fill(null));

  useEffect(() => {
    const inputs = new Array(8).fill().map((_, index) => `Input ${index + 1}`);
    inputs.forEach((input, index) => {
      setTimeout(() => {
        getGeneratedText(input, index);
      }, Math.floor(index / 3) * 1000); //1000 ms delay per X items
    });

    return () => {
      streamRefs.current.forEach((ref) => {
        if (ref && ref.readyState !== SSE.CLOSED) {
          ref.close();
        }
      });
    };
  }, []);

  const updateStreamData = (index, newText) => {
    setStreamData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = updatedData[index] + newText;
      return updatedData;
    });
  };

  const getGeneratedText = async (searchValue, index) => {
    await setDomain.seek("Maximo", "test123");
    let url = "https://genai-rfpadvisor-canada.com/query_dev_stream";
    streamRefs.current[index] = new SSE(url, {
      headers: { "Content-Type": "application/json" },
      payload: JSON.stringify({
        user_id: "william.shi@ibm.com",
        question: searchValue,
        session_id: "test123",
      }),
    });

    const currentStream = streamRefs.current[index];

    currentStream.addEventListener("message", (e) => {
      const data = JSON.parse(e.data);
      console.log("resut", index, data);
      updateStreamData(index, data);
    });
  };

  return (
    <div>
      {streamData.map((data, index) => (
        <div key={index}>
          <h3>Stream {index + 1}:</h3>
          <p>{data}</p>
        </div>
      ))}
    </div>
  );
}
