const domainList = [
  'CcaaS Genesys',
  'Maximo',
  'Oracle',
  'SAP',
  'Salesforce',
  'Automation',
  'SAP SuccessFactors',
  'ECM - Talent Dev and HR Transformation',
  'Enterprise Strategy',
  'Experience Design & Mobile (iX)',
  'Technology Life Cycle Services (Infra)',
  'Adobe',
  'AMS SAP',
  'AMS Custom',
  'Microsoft Dynamics',
  'IBM Payments Centre',
  'DPS Practice',
  'IBM Tech Quality Engineering',
  'Demo One',
  'Demo Two',
  'Demo Three'
];

export default domainList;