import "./Description.scss";
import { Button } from "@carbon/react";
import {useState } from "react";

import { ChevronRight } from "@carbon/icons-react";
import ViewGuideModal from "./ViewGuide";
import AboutUsModal from "./AboutUs";

//Component for text area and view guide that sits on top of the sample questions, on the sidebar
function Description(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  // const [isModalOpenAU, setIsModalOpenAU] = useState(false);

  // const toggleModalAU = () => {
  //   setIsModalOpenAU(!isModalOpenAU);
  // };

  return (
    <>
    {/* <p style={{display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        justifyContent: "center",
        padding: "1.5em"}}>
          <Button
            onClick={toggleModalAU}
            className="guide-button"
            kind="secondary"
          >
            {"About Us"}
            <ChevronRight className="guide-chevron" />
          </Button>
        </p> */}
    <ViewGuideModal
          toggleModal={toggleModal}
          isModalOpen={isModalOpen}
        />
    {/* <AboutUsModal
          toggleModalAU={toggleModalAU}
          isModalOpenAU={isModalOpenAU}
        /> */}
    <div style={{ padding: "10px", textAlign: "left", color: "black" }}>
      <p className="instructions-header">How to Use Me:</p>
      <p className="instructions">
        To generate the best responses to your RFP questions here are some
        guiding principles:
      </p>
      <div style={{
        paddingTop: "1em"}}>
        <p>
          <Button
            onClick={toggleModal}
            className="guide-button"
            kind="secondary"
          >
            {"User Guide"}
            <ChevronRight className="guide-chevron" />
          </Button>
        </p>
      </div>
    </div>
    </>
  );
}

export default Description;
