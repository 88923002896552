import AppID from "ibmcloud-appid-js";
import React, { useEffect, useState } from "react";
import "./Login.css";
import arrowIcon from "../assets/login_arrow.svg";

const REACT_APP_APPID_CLIENT_ID = process.env.REACT_APP_APPID_CLIENT_ID;
const REACT_APP_APPID_DISCOVERY_URL = process.env.REACT_APP_APPID_DISCOVERY_URL;
const AUTHENTICATED = "appid_authenticated";

function Login(props) {
  const [noAccessLevel, setNoAccessLevel] = useState(false);
  const appID = new AppID();

  const initAppID = async () => {
    console.log("AppID config ", REACT_APP_APPID_CLIENT_ID, REACT_APP_APPID_DISCOVERY_URL);
    await appID.init({
      clientId: REACT_APP_APPID_CLIENT_ID,
      discoveryEndpoint: REACT_APP_APPID_DISCOVERY_URL,
    });
  };

  const handleLoginClick = async (e) => {
    try {
      e.preventDefault();
      const tokens = await appID.signin();
      console.log(tokens);
      const scopes = tokens.accessTokenPayload.scope;
      console.log(scopes);
      if (scopes.includes(AUTHENTICATED)) {
        setNoAccessLevel(false);
        props.setAuth();
        props.setUserId(tokens.idTokenPayload.email);
      } else {
        setNoAccessLevel(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initAppID();
  });

  return (
    <div className="login">
      <video width={"100%"} height={500} autoPlay muted loop>
        <source
          src="https://genai-rfp-custom.s3-web.us-east.cloud-object-storage.appdomain.cloud/3ebe8880f32b5ebcb531f6ccb97bb0ba.mp4"
          type="video/mp4"
        />
      </video>
      <div className="login-btn-box" onClick={handleLoginClick}>
        <button className="login-btn">Login</button>
        <img
          className="login-arrow-margin"
          width={"12.25px"}
          src={arrowIcon}
          alt="login button"
        />
      </div>
      {noAccessLevel ?? (
        <p>
          You don't have access to the RFP Advisor, please contact administrator to
          get access.
        </p>
      )}
    </div>
  );
}

export default Login;
