import axios from "axios";
import { SSE } from "sse.js";
const REACT_APP_GENAIRFP_ROOT_URL = process.env.REACT_APP_GENAIRFP_ROOT_URL;
const REACT_APP_GENAIRFP_SET_DOMAIN = process.env.REACT_APP_GENAIRFP_SET_DOMAIN;
const REACT_APP_GENAIRFP_FEEDBACK = process.env.REACT_APP_GENAIRFP_FEEDBACK;
const REACT_APP_GENAIRFP_QUERY_STREAM = process.env.REACT_APP_GENAIRFP_QUERY_STREAM;
const REACT_APP_GENAIRFP_BATCH_UPLOAD = process.env.REACT_APP_GENAIRFP_BATCH_UPLOAD;
const REACT_APP_GENAIRFP_BAMHEALTHCHECK = process.env.REACT_APP_GENAIRFP_BAMHEALTHCHECK;

// export const responseFromLLM = {
//   async seek(questions, sessionId) {
//     const requestOptions = {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         question: questions,
//         session_id: sessionId
//       }),
//     };

//     var response = await fetch(GENAIRFP_PROXY_BACKEND_URL + "/query_dev_70b", requestOptions);
//     return response;
//   },
// };

export const updateFeedback = {
  async seek(questionId, isHelpful, reason = '', comment = '', boxlinks = '') {

    const questionJson = {  
      'reason' : reason,
      'comment' : comment,
      'boxlinks' : boxlinks
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question_id: questionId,
        isHelpful: isHelpful,
        questionJson: JSON.stringify(questionJson)
      }),
    };

    var response = await fetch(REACT_APP_GENAIRFP_ROOT_URL + REACT_APP_GENAIRFP_FEEDBACK, requestOptions);
    return response;
  },
};

export const streamResponse = (searchValue, sessionId, userId) => {
  return new SSE(REACT_APP_GENAIRFP_ROOT_URL + REACT_APP_GENAIRFP_QUERY_STREAM, {
    headers: { "Content-Type": "application/json" },
    payload: JSON.stringify({
      question: searchValue,
      session_id: sessionId,
      user_id: userId,
    }),
  });
};

export const setDomain = {
  async seek(questions, sessionId) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        domain: questions,
        session_id: sessionId
      }),
    };


    var response = await fetch(REACT_APP_GENAIRFP_ROOT_URL + REACT_APP_GENAIRFP_SET_DOMAIN, requestOptions);
    return response;
  },
};

export const bamHealthCheck = {
  async seek() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        env: process.env.REACT_APP_GENAIRFP_ENV,
      }),
    };

    var response = await fetch(REACT_APP_GENAIRFP_ROOT_URL + REACT_APP_GENAIRFP_BAMHEALTHCHECK, requestOptions);
    return response;
  },
};


export const batchUpload = {
  async seek(questionList, sessionId) {
    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     session_id: sessionId,
    //     questionList: questionList
    //   }),
    // };

    let body = {
        session_id: sessionId,
        questionList: questionList
    }
    // var response = await fetch(GENAIRFP_PROXY_BACKEND_URL + "/batch_dev", requestOptions);
    var response = axios.post(REACT_APP_GENAIRFP_ROOT_URL + REACT_APP_GENAIRFP_BATCH_UPLOAD, body)
    return response;
  },
};

