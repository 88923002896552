import { Container, Image, Navbar, Form } from "react-bootstrap";
import { HeaderNavigation, HeaderMenuItem } from "@carbon/react";
import {useState } from "react";
import AboutUsModal from "./AboutUs";
import "./Header.css";
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/carbon-components@10.56.0/css/carbon-components.min.css"
  integrity="sha256-MUaZ7++zEaoaf2tNDR5QCH94pfVcpBu/upl8jQOMybU="
  crossorigin="anonymous"
></link>;

export function Header(props) {
  const [isModalOpenAU, setIsModalOpenAU] = useState(false);

  const toggleModalAU = () => {
    setIsModalOpenAU(!isModalOpenAU);
  };
  const handleToggle = () => {
    props.setShowFileUpload((currentState) => !currentState);
  };
  return (
    <Navbar
      className="header"
      expand="lg"
      style={{ paddingLeft: 0, paddingRight: 0, width: "100%", margin: 0 }}
    >
      <AboutUsModal
          toggleModalAU={toggleModalAU}
          isModalOpenAU={isModalOpenAU}
        />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <Navbar.Brand href="#home" style={{ marginLeft: "0", paddingRight: "1em" }}>
          <h2 style={{ color: "white" }}>
            <strong>RFP Advisor 🇨🇦</strong>
          </h2>
        </Navbar.Brand>
        {props.setShowToggle === true ? <HeaderNavigation style={{ marginLeft: "auto"}}>
            <HeaderMenuItem 
            onClick={toggleModalAU}
            style={{ marginLeft: "auto", color: "white", backgroundColor:"black",cursor:"pointer"}}>About Us</HeaderMenuItem>
        </HeaderNavigation>
        :("") }
        {/* {props.setShowToggle === true && process.env.REACT_APP_GENAIRFP_ENV !== 'prod' ? <div>
          <Form.Check
            className="batchupload-toggle"
            type="switch"
            label="Toggle BatchUpload"
            checked={props.showBatchUpload}
            onChange={handleToggle}
            style={{ marginRight: 0 }}
          />
        </div> 
        :("") } */}
      </div>
    </Navbar>
  );
}
