import React from "react";
import { Modal, Dropdown, TextInput } from "@carbon/react";
import { updateFeedback } from "../util/api";

//Component for expanded negative feedback form that opens on a thumbs down
function FeedbackModal(props) {
  const dropdownItems = [
    { id: "option-default", text: "" },
    { id: "option-1", text: "You provided no response" },
    { id: "option-2", text: "Partially relevant response" },
    { id: "option-3", text: "Incorrect Response provided" },
    { id: "option-4", text: "Quality of the Response" },
    { id: "option-5", text: "Other" },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [name]: value,
    }));

    if (name === "reason" && value.trim() !== "") {
      props.setIsReasonSelected(true);
    } else if (name === "reason") {
      props.setIsReasonSelected(false);
    }
  };

  const handleClose = () => {
    props.setFeedback(props.feedback);
    props.setShowModal(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateFeedback.seek(
      props.feedback.questionId,
      props.feedback.isHelpful,
      props.feedback.reason,
      props.feedback.comment,
      props.feedback.boxlink
    );
    props.setFeedback(props.feedback);
    props.setShowModal(false);
  };

  return (
    <Modal
      open={props.showModal}
      modalHeading="Sorry, I was not able to help"
      primaryButtonText="Submit"
      primaryButtonDisabled={!props.isReasonSelected}
      secondaryButtonText="Cancel"
      onRequestSubmit={handleSubmit}
      onRequestClose={handleClose}
      onSecondarySubmit={handleClose}
    >
      <form>
        <Dropdown
          className="reason-dropdown"
          id="error-type"
          titleText="What did I get wrong?"
          label="Select a reason"
          items={dropdownItems}
          itemToString={(item) => (item ? item.text : "")}
          onChange={({ selectedItem }) =>
            handleInputChange({
              target: { name: "reason", value: selectedItem.text },
            })
          }
        />

        <TextInput
          id="comment"
          labelText="Comments"
          placeholder="Add a comment..."
          name="comment"
          value={props.feedback.comment}
          onChange={handleInputChange}
          style={{
            marginBottom: "1rem",
          }}
        />

        <TextInput
          id="box-link"
          labelText="You can add Box links to documents you think will help grow my knowledge base"
          placeholder="Add a link..."
          name="boxlink"
          value={props.feedback.boxlink}
          onChange={handleInputChange}
          style={{
            marginBottom: "1rem",
          }}
        />
      </form>
    </Modal>
  );
}

export default FeedbackModal;
