import { Modal } from "@carbon/react";
import './AboutUs.scss';
import mo_img from "../assets/mohammad_aboutus.jpeg";
import namrata_img from "../assets/namrata_aboutus.png";
import william_img from "../assets/william_aboutus.jpeg";
import jin_img from "../assets/jin_aboutus.jpeg";
import jake_img from "../assets/jake_aboutus.jpeg";
import hammad_img from "../assets/hammad_aboutus.jpg";


function AboutUsModal(props) {
  const teamMembers = [
    { name: 'Mohammad Tahle', role: 'Senior Consultant', email: "mohammad.tahle@ibm.com", imageUrl: mo_img },
    { name: 'Namrata Sharma', role: 'Senior Data Scientist & Consultant', email: "namrata.sharma@ibm.com", imageUrl: namrata_img },
    { name: 'William Shi', role: 'Software Developer', email: "william.shi@ibm.com", imageUrl: william_img },
    { name: 'Jin Zeng', role: 'Senior Data Scientist & Consultant', email: "jin.zeng@ibm.com", imageUrl: jin_img },
    { name: 'Jake Kim', role: 'Senior Product Designer', email: "jake.kim@ibm.com", imageUrl: jake_img },
    { name: 'Hammad Shaikh', role: 'Software Developer Intern', email: "hammad.shaikh@ibm.com", imageUrl: hammad_img },
    // Add more team members here
  ];
  return (
    // modalHeading={<p style={{width:"100%", fontSize:"larger", paddingRight:"0"}}>The RFP Advisor Canada Team</p>}
    <Modal size="md" onRequestClose={props.toggleModalAU} open={props.isModalOpenAU} isFullWidth passiveModal>
      <modalHeading style={{fontSize:"larger", fontWeight:"bold"}} >The RFP Advisor Canada Team</modalHeading>
        <div>
          <div>
            <div className="team">
              {teamMembers.map(member => (
                <div style={{textAlign: "center"}} key={member.name} className="team-member">
                  <img src={member.imageUrl} alt={member.name} />
                  <p><strong>{member.name}</strong></p>
                  <p style={{maxWidth:"10em"}}>{member.role}</p>
                  <p style={{fontStyle: "italic", fontSize: "smaller", textAlign:"center"}}>{member.email}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        </Modal>
  );
}

export default AboutUsModal;
