import { Col, Container, Row, Button } from "react-bootstrap";
import { ThumbsUp, ThumbsDown } from "@carbon/icons-react";
import "./FeedbackThumbsUpDown.scss";
import { useState } from "react";
import { updateFeedback} from "../util/api";

function FeedbackThumbsUpDown(props) {
  const [feedbackButtonPositiveClass, setFeedbackButtonPositiveClass] =
    useState("feedback-button");
  const [feedbackButtonPositiveClassIcon, setFeedbackButtonPositiveClassIcon] =
    useState("feedback-button-positive");
  const [feedbackButtonNegativeClass, setFeedbackButtonNegativeClass] =
    useState("feedback-button");
  const [feedbackButtonNegativeClassIcon, setFeedbackButtonNegativeClassIcon] =
    useState("feedback-button-negative");
  const [feedbackButtonNegativeHidden, setFeedbackButtonNegativeHidden] =
    useState(false);
  const [feedbackButtonPositiveHidden, setFeedbackButtonPositiveHidden] =
    useState(false);
  const [disableFeedback, setDisableFeedback] = useState(false);

  function handleButtonClick(id, feedback) {
    handleFeedBack(id, feedback);
    feedbackButtonSelectedState(feedback);
  }

  function handleFeedBack(id, feedback) {
    updateFeedback.seek(id, feedback);

    if (feedback === "negative") {
      props.setShowModal(true);
      props.setFeedback((prevFeedback) => ({
        ...prevFeedback,
        questionId: id,
        isHelpful: feedback,
      }));
    }
    const updatedItems = props.items.map((item) => {
      if (item.id && item.id === id) {
        const updatedItem = {
          ...item,
          feedback: feedback,
          isFeedBckVisible: true,
        };
        return updatedItem;
      }
      return item;
    });
    props.setItems(updatedItems);
  }

  //updates the classname of the buttons, to reflect their current state
  function feedbackButtonSelectedState(state) {
    setDisableFeedback(true);
    console.log("chaing");
    if (state === "positive") {
      setFeedbackButtonPositiveClassIcon("feedback-button-positive-active");
      setFeedbackButtonNegativeClass("feedback-button visbility-hidden");
      setFeedbackButtonNegativeHidden(true);
    } else if (state === "negative") {
      setFeedbackButtonNegativeClassIcon("feedback-button-negative-active");
      setFeedbackButtonPositiveClass("feedback-button visbility-hidden");
      setFeedbackButtonPositiveHidden(true);
    }
  }

  return (
    <Row>
      <Col md={12}>
        <Container>
          <Button
            hidden={feedbackButtonPositiveHidden}
            disabled={disableFeedback}
            className={feedbackButtonPositiveClass}
            id="send-btn"
            onClick={() => handleButtonClick(props.item.id, "positive")}
          >
            <ThumbsUp className={feedbackButtonPositiveClassIcon} />
          </Button>
          <Button
            hidden={feedbackButtonNegativeHidden}
            disabled={disableFeedback}
            className={feedbackButtonNegativeClass}
            id="send-btn"
            onClick={() => handleButtonClick(props.item.id, "negative")}
          >
            <ThumbsDown className={feedbackButtonNegativeClassIcon} />
          </Button>
        </Container>
      </Col>
    </Row>
  );
}

export default FeedbackThumbsUpDown;
