import { Modal } from "@carbon/react";

function ViewGuideModal(props) {
  return (
    <Modal  onRequestClose={props.toggleModal} open={props.isModalOpen} modalHeading="How to use me guide" passiveModal>
          <div>
            <p className="instructions-header">
              <strong>
                Be specific to receive accurate and helpful responses
              </strong>
            </p>
            <p className="instructions">
              For example, instead of a broad question like “What is your Equity
              Strategy?”, provide more context and be targeted such as:
            </p>
            <p className="instructions">
              “Describe how IBM is committed to employment equity, diversity,
              and inclusion in the workspace. Explain IBM’s hiring practices in
              place to support that commitment.”
            </p>
            <p className="instructions-header">
              <strong>
                Using clear, concise language that provides sufficient
                information.
              </strong>
            </p>
            <p className="instructions">
              Pose questions in clear, concise language, avoiding jargon or
              complex phrases. Ensure there is enough information for me to
              understand and provide relevant responses.
            </p>
            <p className="instructions">
              For example: “For the delivery of the project, the proponent shall
              conduct and meet testing requirements in attachment A section
              4.1.4” does not specify the scope of testing referenced. A better
              approach is:
            </p>
            <p className="instructions">
              “What is IBM’s standardized acceptance testing criteria and
              sign-off procedure for X implementation?”
            </p>
            <p className="instructions-header">
              <strong>Test, refine & provide feedback.</strong>
            </p>
            <p className="instructions">
              If I generate useful responses, please rate them by selecting the
              "thumbs up" icon. If I do not, try rephrasing the question or
              providing more context. If still unsatisfied with the generated
              response, click the "thumbs down" icon and add comments. This is a
              great way for you to also share links to content to help me answer
              similar questions in the future.
            </p>
            <p className="instructions">
              Don't forget to rate your overall experience by clicking the
              Feedback button to the right!
            </p>
          </div>
        </Modal>
  );
}

export default ViewGuideModal;
