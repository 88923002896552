import "./App.scss";
import "./components/Description.scss";
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import { Header } from "./components/Header";
import { ChatBox } from "./components/ChatBox";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import SideBar from "./components/SideBar";
import Description from "./components/Description";
import { TestStream } from "./components/TestStream";
import Login from "./components/Login";
import { InlineNotification } from '@carbon/react';

function App() {
  const [items, setItems] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [isThereInput, setIsThereInput] = useState(false);
  const [input, setInput] = useState("");
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showToggleUpload, setShowToggleUpload] = useState(false);
  const [isDomainSet, setIsDomainSet] = useState(true);
  const [currentDomainQuestion, setCurrentDomainQuestion] = useState([]);
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (authenticated) {
      const script = document.createElement("script");
      script.src =
        "https://beeheard.dal1a.cirrus.ibm.com/survey/preconfig/eHsZB26f.js";
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [authenticated]);

  useEffect(() => {
    const items = [];
    var initalInput = "Hello!";
    var item = {
      type: "first",
      answer: initalInput,
    };
    items.push(item);
    initalInput =
      "I'm your assistant for drafting RFP responses. I'm here to help you succeed by answering any questions you may have and provide you with high-quality responses. Remember, the more descriptive your questions are, the more accurate my responses will likely be.";

    item = {
      type: "second",
      answer: initalInput,
    };
    items.push(item);

    initalInput =
      "However, I am not perfect and there may be cases where my responses may not meet your expectations. If that happens, please don’t hesitate to let me know. Your feedback is invaluable to help me learn and grow.\n\n Lets work together to create a successful RFP response!";

    item = {
      type: "third",
      answer: initalInput,
    };

    items.push(item);
    setItems([...items]);
    setSessionId(uuid());
  }, [items.length === 0]);

  const loginUser = () => {
    console.log("Authenticated user");
    setAuthenticated(true);
  };
  // const logoutUser = () => {
  //   console.log('Logging out')
  //   setAuthenticated(false);
  // }

  if (!authenticated) {
    return (
      <div>
        <Header
          showFileUpload={false}
          setShowFileUpload={setShowFileUpload}
          showToggleUpload={showToggleUpload}
          setShowToggle={false}
        />
        <Login setAuth={loginUser} setUserId={setUserId} />
      </div>
    );
  } else {
    return (
      <div className="App">
        <Header
          showFileUpload={showFileUpload}
          setShowFileUpload={setShowFileUpload}
          setShowToggle={true}
        />
        {showFileUpload ? (
          <Col style={{ marginTop: "40px" }} xs={12} md={0} className="p-3">
            <TestStream sessionId={sessionId} />
          </Col>
        ) : (
          <Container fluid>
            <Row>
              <Col
                className="sidecol"
                style={{ width: "400px" }}
                xs={{ span: 12, order: 2 }}
                md={{ span: 4, order: 1 }}
              >
                <Description/>
                <SideBar
                  data={currentDomainQuestion}
                  setItems={setItems}
                  items={items}
                  setIsThereInput={setIsThereInput}
                  isThereInput={isThereInput}
                  input={input}
                  setInput={setInput}
                  loading={loading}
                />
              </Col>
              <Col
                style={{ backgroundColor: "#f4f4f4" }}
                className="d-flex justify-content-center"
                xs={{ span: 12, order: 3 }}
                md={{ span: 8, order: 2 }}
              >
                <Container>
                  <ChatBox
                    setCurrentDomainQuestion={setCurrentDomainQuestion}
                    items={items}
                    setItems={setItems}
                    sessionId={sessionId}
                    setIsThereInput={setIsThereInput}
                    isThereInput={isThereInput}
                    input={input}
                    setInput={setInput}
                    isDomainSet={isDomainSet}
                    setIsDomainSet={setIsDomainSet}
                    loading={loading}
                    setLoading={setLoading}
                    userId={userId}
                  />
                </Container>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }
}

export default App;
